.search {
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  padding: 3%;
}
h4 a {
  color: #197cda;
  text-transform: capitalize;
}
h4 a:hover {
  color: #05529b;
}
a {
  color: rgba(79, 155, 4, 0.781);
  font-weight: 600;
  text-decoration: none;
}
a:visited {
  color: #4b0088;
}
a:hover {
  color: rgb(18, 146, 1);
}
.dropdown > ul {
  padding: 0;
  width: 100%;
}
.dropdown > button {
  width: 100%;
}
.dropdownMenu.shown {
  display: block !important;
}
.filter {
  padding: 0;
  padding-left: 2%;
}
.searchResult {
  margin: 2% 0;
  font-size: 110%;
}
form > div {
  padding: 0 !important;
}
input[type="number"] {
  width: 100%;
}
.tab {
  padding: 0.5% 1%;
  text-align: center;
  background-color: #eee;
  border-top: 3px solid #eee;
}
.tab.activeTab {
  background-color: #fff;
  border-top: 3px solid #0d6efd;
}
.contentTab {
  display: none;
}
.contentTab.active {
  display: block;
}
.imagesTab {
  column-count: 3;
  column-gap: 2%;
}
.imagesTab .imgs {
  width: 100%;
  margin: 3% 0;
}
.video {
  width: 31%;
  margin: 1%;
  display: inline-block;
  padding: 0 0 0 1%;
}
.vidTab video {
  width: 100%;
  margin: 2% 0 5%;
}
.result {
  padding-top: 2%;
}
:is(div) > .lds_roller {
  text-align: center;
  position: absolute;
  left: 47.5%;
}
.lds_roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: 7% !important;
}
.lds_roller div {
  animation: lds_roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds_roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #197cda;
  margin: -4px 0 0 -4px;
}
.lds_roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds_roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds_roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds_roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds_roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds_roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds_roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds_roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds_roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds_roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds_roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds_roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds_roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds_roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds_roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds_roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds_roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.textTab > div {
  margin: 3% 0;
}
.textTab div:first-child {
  margin-top: 0;
}
@media (max-width: 960px) {
  .video {
    width: 46%;
    margin: 2%;
    display: inline-block;
    padding: 0 0 0 1%;
  }
  :is(div) > .lds_roller {
    left: 45%;
  }
}
@media (max-width: 480px) {
  .filter {
    padding: 2% !important;
  }
  form > div:first-child {
    padding: 0;
    padding-left: 2% !important;
  }
  form > div:last-child {
    padding: 0;
    padding-right: 2% !important;
  }
  .imagesTab {
    column-count: 2;
    column-gap: 2%;
  }
  .imagesTab .imgs {
    width: 100%;
    margin: 2% 0;
  }
  .video {
    width: 96%;
    margin: 2%;
    display: inline-block;
    padding: 0 0 0 1%;
  }
  :is(div) > .lds_roller {
    left: 40%;
  }
}
