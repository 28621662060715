body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css_search__2KtnQ {
  padding: 0% !important;
}

/* Bad practice but needed to fix quickly */
.bg-dark {
  background-color: #921e46 !important;
}

.navbar-expand-lg {
  flex-wrap: nowrap;
  justify-content: space-between !important;
  padding-right: 20px;
}

.css_textTab__O93uW div:first-child {
     margin-top: 8px !important;
}

.css_filter__Bit-8 {
  padding-right: 2.5% !important;
}

.css_searchResult__1T0Xx {
  display: grid !important;
  margin-left: 15% !important;
}

.css_contentTab__2H1QU {
  display: grid !important;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.css_contentTab__2H1QU  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border: 3px solid white;
    box-shadow: 0px 0px 8px rgba(0,0,0,.3);
}

.navbar {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

btn btn-primary w-20 {
  margin-right: 20px;
}

/* Styles the lightbox, removes it from sight and adds the fade-in transition */
.lightbox-target {
  position: fixed;
  top: -100%;
  width: 100%;
  background: rgba(0,0,0,.9);
  width: 100%;
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
  overflow: hidden;
}

/* Styles the lightbox image, centers it vertically and horizontally, adds the zoom-in transition and makes it responsive using a combination of margin and absolute positioning */

.lightbox-question {
  margin: auto;
  position: absolute;
  top: 15%;
  left: 50%;
  bottom: 0;
  font-size: 3em;
  color: white;
}

.lightbox-answer {
  margin: auto;
  position: absolute;
  top: 25%;
  left: 50%;
  bottom: 0;
  font-size: 2em;
  color: white;
  font-weight: 200;
}

.lightbox-title {
  margin: auto;
  position: absolute;
  top: 35%;
  left: 50%;
  bottom: 0;
  font-size: 2em;
  color: white;
  font-weight: 200;
}

.lightbox-tag {
  margin: auto;
  position: absolute;
  top: 45%;
  left: 50%;
  bottom: 0;
  font-size: 2em;
  color: white;
  font-weight: 200;
}

.lightbox-target img {
  margin: auto;
  position: absolute;
  top: 0;
  left:0;
  right:60%;
  bottom: 0;
  max-height: 0%;
  height: 60%;
  width: 45%;
  max-width: 0%;
  border: 3px solid white;
  box-shadow: 0px 0px 8px rgba(0,0,0,.3);
  box-sizing: border-box;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

/* Styles the close link, adds the slide down transition */

a.lightbox-close {
  display: block;
  width:50px;
  height:50px;
  box-sizing: border-box;
  background: white;
  color: black;
  text-decoration: none;
  position: absolute;
  top: -80px;
  right: 0;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

/* Provides part of the "X" to eliminate an image from the close link */

a.lightbox-close:before {
  content: "";
  display: block;
  height: 30px;
  width: 1px;
  background: black;
  position: absolute;
  left: 26px;
  top:10px;
  -webkit-transform:rotate(45deg);
  -moz-transform:rotate(45deg);
  -o-transform:rotate(45deg);
  transform:rotate(45deg);
}

/* Provides part of the "X" to eliminate an image from the close link */

a.lightbox-close:after {
  content: "";
  display: block;
  height: 30px;
  width: 1px;
  background: black;
  position: absolute;
  left: 26px;
  top:10px;
  -webkit-transform:rotate(-45deg);
  -moz-transform:rotate(-45deg);
  -o-transform:rotate(-45deg);
  transform:rotate(-45deg);
}

/* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */

.lightbox-target:target {
  opacity: 1;
  top: 0;
  left:0;
  bottom: 0;
  overflow:scroll;
}

.lightbox-target:target img {
  max-height: 100%;
  max-width: 100%;
}

.lightbox-target:target a.lightbox-close {
  top: 0;
}
